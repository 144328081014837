<template>
  <div>
    <div class="card-toolbar mb-5">
      <button
        @click="openAdvancedSearch"
        class="btn btn-outline-primary font-weight-bolder ml-5"
      >
        <span
          ><i class="la la-filter"></i>
          <span>{{ $t("advanced_filter") }}</span></span
        >
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="subdomain">{{
                $t("reports_subscriptions.company")
              }}</label>
              <multiselect
                v-model="company"
                :placeholder="$t('reports_subscriptions.company')"
                label="name"
                track-by="id"
                :options="companies"
                :multiple="false"
                :taggable="false"
                :show-labels="false"
                :show-no-options="false"
                :show-no-results="false"
                @search-change="getCompanies($event)"
                :internal-search="false"
              >
              </multiselect>
            </div>
            <!-- <div class="form-group col-md-6">
              <label for="name">{{ $t("commission.agent_name") }}</label>
              <multiselect
                v-model="agent"
                :placeholder="$t('commission.agent_name')"
                label="name"
                track-by="id"
                :options="agents"
                :multiple="false"
                :taggable="false"
                :show-labels="false"
                :show-no-options="false"
                :show-no-results="false"
                :internal-search="false">
              </multiselect>
            </div> -->
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{
                $t("reports_subscriptions.from_date")
              }}</label>
              <input
                v-model="filters.from_date"
                type="date"
                id="from_date"
                class="form-control"
              />
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{
                $t("reports_subscriptions.to_date")
              }}</label>
              <input
                v-model="filters.to_date"
                type="date"
                id="to_date"
                class="form-control"
              />
            </div>

            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button
                type="button"
                @click="doFilter"
                class="btn btn-primary mt-auto mr-2"
                id="m_search"
              >
                <span
                  ><i class="fas fa-search"></i>
                  <span>{{ $t("search") }}</span>
                </span>
              </button>
              <button
                type="button"
                @click="resetFilter"
                class="btn btn-danger mt-auto mr-2"
                id="m_reset"
              >
                <span
                  ><i class="fas fa-trash-restore"></i>
                  <span>{{ $t("reset_search") }}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <div class="mb-5">
          <div class="d-flex justify-content-end">
            <custom-export-data
              :data-list="dataList"
              :route="mainRoute"
              :filters="filters"
              :fields="json_fields"
              :file-name="$t('MENU.reports_subscriptions')"
            ></custom-export-data>
          </div>
        </div>

        <v-server-table
          :class="'dataTable table-row-dashed'"
          :columns="columns"
          :options="options"
          ref="table"
        >
        </v-server-table>

        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  components: {},
  data() {
    return {
      mainRoute: "/subscription/subscriptions",
      mainRouteDependency: "base/dependency",

      showAdvancedSearch: false,

      filters: {
        agent_id: null,
        company_id: null,
        from_date: null,
        to_date: null,
      },

      columns: [
        "plan_name",
        "first_name",
        "last_name",
        "phone",
        "email",
        "payment_type_name",
        "subscription_type_name",
        "subscription_price",
        "start_date",
        "end_date",
      ],

      validation: [],
      dataList: [],
      companies: [],
      company: null,
      agents: [],
      agent: null,
    };
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t("reports_subscriptions.plan_name")] = "plan_name";
      fields[this.$t("reports_subscriptions.first_name")] = "first_name";
      fields[this.$t("reports_subscriptions.last_name")] = "last_name";
      fields[this.$t("reports_subscriptions.phone")] = "phone";
      fields[this.$t("reports_subscriptions.email")] = "email";
      fields[this.$t("reports_subscriptions.payment_type")] =
        "payment_type_name";
      fields[this.$t("reports_subscriptions.subscription_type")] =
        "subscription_type_name";
      fields[this.$t("reports_subscriptions.subscription_price")] =
        "subscription_price";
      fields[this.$t("reports_subscriptions.start_date")] = "start_date";
      fields[this.$t("reports_subscriptions.end_date")] = "end_date";

      return fields;
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t("Something_went_wrong"),
          filter: "",
          limit: that.$t("records"),
          filterBy: that.$t("Filter") + " {column}",
          count: " ",
          filterPlaceholder: that.$t("Search_query"),
          loading: that.$t("Loading") + "...",
        },
        headings: {
          plan_name: that.$t("reports_subscriptions.plan_name"),
          first_name: that.$t("reports_subscriptions.first_name"),
          last_name: that.$t("reports_subscriptions.last_name"),
          phone: that.$t("reports_subscriptions.phone"),
          email: that.$t("reports_subscriptions.email"),
          payment_type_name: that.$t("reports_subscriptions.payment_type"),
          subscription_type_name: that.$t(
            "reports_subscriptions.subscription_type"
          ),
          subscription_price: that.$t(
            "reports_subscriptions.subscription_price"
          ),
          start_date: that.$t("reports_subscriptions.start_date"),
          end_date: that.$t("reports_subscriptions.end_date"),
        },
        sortable: ["created_at"],
        filterByColumn: false,
        filterable: false,
        customFilters: [
          {
            name: "alphabet",
          },
        ],
        orderBy: { column: "created_at" },

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: { chunk: 5, dropdown: false },
        skin: "table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline",
        sortIcon: {
          base: "fas ml-2",
          up: "fa-sort-alpha-up",
          down: "fa-sort-alpha-down",
          is: "fa-sort",
        },
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          };
          return ApiService.query(that.mainRoute, { ..._params });
        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          };
        },
      };
    },
  },
  watch: {
    company: function (val) {
      if (val) {
        this.filters.company_id = val.id;
      } else {
        this.filters.company_id = null;
      }
    },
    // agent: function (val) {
    //   if (val) {
    //     this.filters.agent_id = val.id;
    //   } else {
    //     this.filters.agent_id = null;
    //   }
    // }
  },
  mounted() {
    // let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.reports_subscriptions") },
    ]);
    // this.getAgents();
  },
  methods: {
    getFetch() {
      this.$refs.table.refresh();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.company_id = null;
      this.filters.agent_id = null;
      this.company = null;

      this.$refs.table.refresh();
    },
    getCompanies(filter) {
      if (filter && filter.length >= 3) {
        ApiService.query(this.mainRouteDependency + "/user_companies", {
          params: { filter: filter },
        }).then((response) => {
          this.companies = response.data.data;
        });
      }
    },
    getAgents() {
      ApiService.get(this.mainRouteDependency + "/agents").then((response) => {
        this.agents = response.data.data;
      });
    },
  },
};
</script>
